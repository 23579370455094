import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import css from 'src/theme/css';
import RichText, * as richText from 'src/components/RichText';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { Flex, Grid } from 'src/components/FlexBox';
import SmartButton from 'src/components/SmartButton';

const ThreeUpModule = ({
  feature1,
  feature2,
  feature3,
  subtitle,
  title,
  items,
  moduleTheme,
  finePrint,
  buttons,
  ...other
}) => (
  <ModuleWrapper
    {...other}
    flexDirection="column"
    colorMode={moduleTheme === 'Black' ? 'dark' : 'light'}
    css={css({ px: 'pageMargin', py: 'sectionMargin' })}
  >
    <Flex
      gy="sectionMargin"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
      <TextStack>
        {title && <Text variant="heading.xxl">{title}</Text>}
        {subtitle && <Text css={css({ maxWidth: 9 })}>{subtitle}</Text>}
      </TextStack>
      <Grid
        gridTemplateColumns={['1fr', null, '1fr 1fr 1fr']}
        gridGap={4}
        alignSelf="stretch"
      >
        {items
          ? items.map((item) => (
              <RichText
                key={item.id}
                json={item.content.json}
                alignment={richText.alignments.center}
              />
            ))
          : [feature1, feature2, feature3].map(
              ({ id, image, title, description }) => (
                <TextStack
                  key={id}
                  alignItems="center"
                  css={css({ maxWidth: 8 })}
                >
                  <Img
                    {...image}
                    objectFit="contain"
                    css={css({ width: 6, height: 6 })}
                  />
                  {title && <Text variant="caps">{title}</Text>}
                  {description && <Text>{description}</Text>}
                </TextStack>
              )
            )}
      </Grid>
      {finePrint && <Text variant="small">{finePrint}</Text>}
      {buttons &&
        buttons.map((button) => (
          <SmartButton key={button.id} {...button} isBlank={true} />
        ))}
    </Flex>
  </ModuleWrapper>
);

export default ThreeUpModule;

export const query = graphql`
  fragment ThreeUpModuleFragment on ContentfulThreeUpModule {
    id
    title
    subtitle
    slug
    moduleTheme
    feature1 {
      id
      title
      description
      image {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    feature2 {
      id
      title
      description
      image {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    feature3 {
      id
      title
      description
      image {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    items {
      id
      content {
        json
      }
    }
    buttons {
      ...ButtonFragment
    }
  }
`;
